<template>
  <div>
    <article>
      <h3 class="center">{{ appName }}App客户端使用的权限说明</h3>
      <section>
        <p>
          1、为了客户端的业务能够完整安全的被使用，客户端本身和接入的第三方SDK都可能会申请一些权限，并使用这些权限对应提供的能力。
        </p>
        <p>
          2、部分敏感权限需要您授予才能使用，如果您不授予这些敏感权限，仍可以使用客户端的大部分的功能，部分需要上述权限的业务可能会受到影响，无法使用。
        </p>
        <p>3、客户端使用的权限如下表所示：</p>
      </section>
      <section>
        <div class="table">
          <div class="t-head">
            <ul>
              <li v-for="(item, index) in option.column" :key="index">{{ item.label }}</li>
            </ul>
          </div>
          <div class="t-body" v-for="(item, index) in tableData" :key="index">
            <ul>
              <li v-for="(context, i) in option.column" :key="i">
                <span v-if="context.prop !== 'Links'">{{ item[context.prop] }}</span>
                <span v-else class="align-start"
                  ><a :href="item[context.prop]" v-if="typeof item[context.prop] === 'string'">{{
                    item[context.prop]
                  }}</a>
                  <a
                    :href="link"
                    v-for="(link, lIndex) in item[context.prop]"
                    :key="lIndex"
                    v-show="Array.isArray(item[context.prop])"
                    >{{ link }} <br
                  /></a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
  export default {
    name: 'UserReleaseCommitment',
    components: {},
    props: {},
    data() {
      return {
        tableData: [
          {
            PermissionName: 'android.permission.SYSTEM_ALERT_WINDOW',
            PermissionDescription: '允许程序显示系统窗口',
            Uses: '用于桌面歌词、宠物功能',
          },
          {
            PermissionName: 'android.permission.REORDER_TASKS',
            PermissionDescription: '重新排序系统Z轴运行中的任务',
            Uses: '联盟广告SDK使用',
          },
          {
            PermissionName: 'android.permission.GET_TASKS',
            PermissionDescription: '获取运行任务',
            Uses: '广告/个推push SDK使用',
          },
          {
            PermissionName: 'android.permission.READ_LOGS',
            PermissionDescription: '允许程序读取系统底层日志',
            Uses: '错误日志收集',
          },
          {
            PermissionName: 'android.permission.WRITE_SETTINGS',
            PermissionDescription: '读取或写入系统设置',
            Uses: '用于设置闹钟，通知声，来电铃声',
          },
          {
            PermissionName: 'android.permission.WRITE_SECURE_SETTINGS',
            PermissionDescription: '读取或写入安全系统设置',
            Uses: '联盟广告SDK使用',
          },
          {
            PermissionName: 'android.permission.BROADCAST_PACKAGE_ADDED',
            PermissionDescription: '接收apk包增加的广播',
            Uses: 'umeng push 接入的',
          },
          {
            PermissionName: 'android.permission.BROADCAST_PACKAGE_CHANGED',
            PermissionDescription: '接收apk包改变的广播',
            Uses: 'umeng push 接入的',
          },
          {
            PermissionName: 'android.permission.BROADCAST_PACKAGE_INSTALL',
            PermissionDescription: '接收apk包安装的广播',
            Uses: 'umeng push 接入的',
          },
          {
            PermissionName: 'android.permission.BROADCAST_PACKAGE_REPLACED',
            PermissionDescription: '接收apk包替换的广播',
            Uses: 'umeng push 接入的',
          },
          {
            PermissionName: 'android.permission.WRITE_SYNC_SETTINGS',
            PermissionDescription: '写入同步设置',
            Uses: 'APP保活',
          },
          {
            PermissionName: 'android.permission.BROADCAST_STICKY',
            PermissionDescription: '允许程序收到广播后快速收到下一个广播',
            Uses: '华为广告 com.huawei.hms.client.service.name:ads-splash',
          },
          {
            PermissionName: 'android.permission.INTERNET',
            PermissionDescription: '允许应用使用网络',
            Uses: '所有网络访问',
          },
          {
            PermissionName: 'android.permission.ACCESS_NETWORK_STATE',
            PermissionDescription: '获取网络状态',
            Uses: '日志上报，离线播放判断网络是否变化，下载时判断是否Wifi, 刷新数据时判断网络是否可用',
          },
          {
            PermissionName: 'android.permission.VIBRATE',
            PermissionDescription: '允许程序振动',
            Uses: '一起听互点击头像，场景音乐刻度尺滑动，保活库创建系统通知',
          },
          {
            PermissionName: 'android.permission.REQUEST_INSTALL_PACKAGES',
            PermissionDescription: '允许应用程序请求安装应用',
            Uses: '升级apk安装, h5页面下载apk安装',
          },
          {
            PermissionName: 'android.permission.WAKE_LOCK',
            PermissionDescription: '允许让处理器免于睡眠或屏幕变暗',
            Uses: '视频页面保持高亮，跟唱页面保持高亮',
          },
          {
            PermissionName: 'android.permission.FOREGROUND_SERVICE',
            PermissionDescription: '允许前台服务',
            Uses: '启动播放服务使用',
          },
          {
            PermissionName: 'android.permission.MOUNT_UNMOUNT_FILESYSTEMS',
            PermissionDescription: '挂载、反挂载外部文件系统',
            Uses: '设置系统铃声',
          },
          {
            PermissionName: 'android.permission.ACCESS_WIFI_STATE',
            PermissionDescription: '获取Wi-fi状态',
            Uses: '快手广告',
          },
          {
            PermissionName: 'android.permission.RESTART_PACKAGES',
            PermissionDescription: '杀死后台进程',
            Uses: 'push功能',
          },
          {
            PermissionName: 'android.permission.CHANGE_NETWORK_STATE',
            PermissionDescription: '修改网络连接状态',
            Uses: '离线播放判断网络是否变化',
          },
          {
            PermissionName: 'android.permission.DISABLE_KEYGUARD',
            PermissionDescription: '允许程序禁用键盘锁',
            Uses: '宠物需求（PetComplexHelper）',
          },
          {
            PermissionName: 'android.permission.EXPAND_STATUS_BAR',
            PermissionDescription: '扩展或收缩状态栏',
            Uses: '桌面歌词功能',
          },
          {
            PermissionName: 'android.permission.MANAGE_EXTERNAL_STORAGE',
            PermissionDescription: '系统权限',
            Uses: '音频编辑',
          },
          {
            PermissionName: 'com.vivo.notification.permission.BADGE_ICON',
            PermissionDescription: 'vivo角标/未知自定义权限',
            Uses: '个推com.getui:gtsdk',
          },
          {
            PermissionName: 'android.permission.FOREGROUND_SERVICE',
            PermissionDescription: '允许前台服务',
            Uses: 'androidx.work:work-runtime, open_ad, lobo/source, 推送本地媒体权限（投屏）',
          },
          {
            PermissionName: 'android.permission.CHANGE_WIFI_MULTICAST_STATE',
            PermissionDescription: '允许应用程序接收未直接发送到您设备的数据包',
            Uses: 'lebo/source,乐播投屏',
          },
          {
            PermissionName: 'android.permission.USE_FULL_SCREEN_INTENT',
            PermissionDescription: '应用全屏',
            Uses: 'bxmsdk(变现猫)',
          },
          {
            PermissionName: 'android.permission.QUERY_ALL_PACKAGES',
            PermissionDescription: '获取已安装应用/系统权限',
            Uses: '广告/push com.huawei.hms:ads-base-inner, com.getui:gtc, com.getui:gsido, GDTSDK, wind-sdk, mobad_normal_pub, ',
          },
          {
            PermissionName: 'com.asus.msa.SupplementaryDID.ACCESS',
            PermissionDescription: '允许应用程序通过移动安全联盟获取匿名设备标识符',
            Uses: 'com.tanx:onlyId, com.bytedance.applog:RangersAppLog-Lite-cn,klevinSDK, open_ad广告，唯一标识',
          },
          {
            PermissionName: 'android.permission.AUTHENTICATE_ACCOUNTS',
            PermissionDescription: '允许应用为ACCOUNT_MANAGER扮演一个帐户认证系统',
            Uses: 'App保活',
          },
          {
            PermissionName: 'com.htc.launcher.permission.READ_SETTINGS',
            PermissionDescription: '写入htc桌面配置',
            Uses: '在桌面（htc）应用图标右上方显示未读消息数量,非通用 me.leolin:ShortcutBadger',
          },
          {
            PermissionName: 'com.huawei.android.launcher.permission.CHANGE_BADGE',
            PermissionDescription: '华为自定义权限',
            Uses: '在桌面应用图标右上方显示未读消息数量,非通用 me.leolin:ShortcutBadger',
          },
          {
            PermissionName: 'com.huawei.android.launcher.permission.READ_SETTINGS',
            PermissionDescription: '访问华为桌面配置',
            Uses: '在桌面应用图标右上方显示未读消息数量,非通用 me.leolin:ShortcutBadger',
          },
          {
            PermissionName: 'com.huawei.android.launcher.permission.WRITE_SETTINGS',
            PermissionDescription: '写入华为桌面配置',
            Uses: '在桌面应用图标右上方显示未读消息数量,非通用 me.leolin:ShortcutBadger',
          },
          {
            PermissionName: 'com.oppo.launcher.permission.READ_SETTINGS',
            PermissionDescription: '访问OPPO桌面配置',
            Uses: '在桌面应用图标右上方显示未读消息数量, 非通用 me.leolin:ShortcutBadger',
          },
          {
            PermissionName: 'com.oppo.launcher.permission.WRITE_SETTINGS',
            PermissionDescription: '写入OPPO桌面配置',
            Uses: '在桌面应用图标右上方显示未读消息数量, 非通用 me.leolin:ShortcutBadger',
          },
          {
            PermissionName: 'android.permission.FLASHLIGHT',
            PermissionDescription: '控制手电筒',
            Uses: '用于字节小游戏',
          },
          {
            PermissionName: 'com.coloros.mcs.permission.RECIEVE_MCS_MESSAGE',
            PermissionDescription: 'OPPO推送',
            Uses: '个推SDK的推送',
          },
          {
            PermissionName: 'android.permission.SYSTEMOVERLAY_WINDOW',
            PermissionDescription: '悬浮窗权限',
            Uses: '用于桌面歌词、宠物功能',
          },
          {
            PermissionName: 'android.permission.RECORD_AUDIO',
            PermissionDescription: '录音',
            Uses: 'AI歌手场景用户录入音频',
          },
        ],
        option: {
          column: [
            {
              label: '权限名称',
              prop: 'PermissionName',
            },
            {
              label: '权限说明',
              prop: 'PermissionDescription',
            },
            {
              label: '使用场景',
              prop: 'Uses',
            },
          ],
        },
        appName: '',
        appNameMap: new Map([
          ['kuaiyin', { appName: '快音', moreOpts: false }],
          ['kuaiyintingge', { appName: '快音听歌版' }],
          ['kuaiyinjisuban', { appName: '快音极速版' }],
          ['kuaiyinyue', { appName: '快音悦', moreOpts: false }],
          ['kuaiyinfm', { appName: '快音FM', moreOpts: true }],
        ]),
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      let app_name = 'kuaiyin';
      if (this.$route.query.app_name) {
        app_name = this.$route.query.app_name;
      } else if (window.bridge && window.bridge.getAppName) {
        app_name = window.bridge.getAppName() || 'kuaiyin';
      }

      if (this.$route.query.app_name) {
        app_name = this.$route.query.app_name;
      } else if (window.bridge && window.bridge.getAppName) {
        app_name = window.bridge.getAppName() || 'kuaiyin';
      }

      this.appName = this.appNameMap.get(app_name).appName;
      document.title = `${this.appName}App客户端使用的权限说明`;
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }

  article {
    margin: 0 4%;
  }
  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    margin: 12px 0;
    font-size: 13px;
    text-indent: 2em;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  .align-start {
    text-align: start;
  }
  .table {
    margin: 15px 0;
    border: 1px solid #aaa;
    width: 100%;
    border-bottom: 0;
  }
  .t-head {
    background: #c6c6c6 no-repeat scroll 0 0;
  }
  .t-head ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .t-head ul li {
    width: 20%;
    text-align: center;
  }
  .t-body {
    background-color: #fff;
  }
  .t-body ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #aaaaaa;
  }
  .t-body ul li {
    position: relative;
    width: 20%;
    padding: 4px;
    text-align: center;
    word-break: break-all;
  }
  a {
    color: #007aff;
  }
</style>
